import React from 'react'
import styled, { css } from 'styled-components'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Button from './content/Button'
import Raabye from './Raabye'
import Socials from './Socials'
import { config, useSpring, animated } from 'react-spring'

const RaabyeText = css`
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  ${({ theme }) => theme.lineHeight}
  ${({ theme }) => theme.breakpoints.sm`
    font-size: ${({ theme }) => theme.fontSizes.normal};
  `}
  ${({ theme }) => theme.breakpoints.lg`
    font-size: ${({ theme }) => theme.fontSizes.normal};
  `}
`

const RaabyePlaceHolder = styled.div`
  > div,
  .raabye-span,
  .raabye-mask {
    -webkit-text-stroke-color: transparent !important;
  }
`

const RaabyeBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${({ theme }) => theme.vh(100)};
`

const RaabyeAfter = styled.p`
  text-align: right;
  margin-bottom: 0;
  margin-top: 1rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  ${RaabyeText};
`
const RaabyeAfterSpan = styled(animated.span)`
  margin: 0;
`

const RaabyeBoxInner = styled.div`
  position: relative;
`

const RaabyeBoxEnd = ({ show, dimensions, ...rest }) => {
  const { x } = useSpring({
    to: { x: show ? 1 : 0 },
    config: { ...config.gentle },
  })
  return (
    <RaabyeBoxWrapper {...rest}>
      <RaabyeBoxInner>
        <RaabyePlaceHolder>
          <Raabye />
        </RaabyePlaceHolder>
        <RaabyeAfter>
          <RaabyeAfterSpan
            style={{
              opacity: x.interpolate({
                range: [0, 0.8, 1],
                output: [0, 0, 1],
              }),
              transform: x
                .interpolate({
                  range: [0, 0.8, 1],
                  output: [-20, -20, 0],
                })
                .interpolate((x) => `translateY(${x}px)`),
            }}
          >
            ... for now?
          </RaabyeAfterSpan>
          <Socials />
        </RaabyeAfter>
      </RaabyeBoxInner>
    </RaabyeBoxWrapper>
  )
}

export default RaabyeBoxEnd
