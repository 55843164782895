import React, { useMemo, useEffect } from 'react'
import { useLoader, useThree, useFrame } from 'react-three-fiber'
import {
  SMAAImageLoader,
  EffectComposer,
  EffectPass,
  RenderPass,
  SMAAEffect,
  BloomEffect,
  Resizer,
  NoiseEffect,
  HueSaturationEffect,
  KernelSize,
  BlendFunction,
} from 'postprocessing'

// Fix smaa loader signature
const _load = SMAAImageLoader.prototype.load
SMAAImageLoader.prototype.load = function (_, set) {
  return _load.bind(this)(set)
}

export default function Post({ ratio }) {
  const { gl, scene, camera, size } = useThree()
  const smaa = useLoader(SMAAImageLoader)
  const composer = useMemo(() => {
    const composer = new EffectComposer(gl)
    const renderPass = new RenderPass(scene, camera)

    const smaaEffect = new SMAAEffect(...smaa)
    smaaEffect.edgeDetectionMaterial.setEdgeDetectionThreshold(0.1)
    const smaaPass = new EffectPass(camera, smaaEffect)

    const noisePass = new EffectPass(
      camera,
      new NoiseEffect({
        blendFunction: BlendFunction.OVERLAY,
        premultiply: true,
      })
    )

    const huePass = new EffectPass(
      camera,
      new HueSaturationEffect({
        blendFunction: BlendFunction.SCREEN,
        hue: 0.0,
        saturation: 0.1,
      })
    )

    const bloomPass = new EffectPass(
      camera,
      new BloomEffect({
        blendFunction: BlendFunction.ADD,
        intensity: 0.5, // The bloom intensity.
        blurPass: undefined, // A blur pass.
        width: Resizer.AUTO_SIZE, // render width
        height: Resizer.AUTO_SIZE, // render height
        kernelSize: KernelSize.MEDIUM, // blur kernel size
        luminanceThreshold: 0.15, // luminance threshold. Raise this value to mask out darker elements in the scene.
        luminanceSmoothing: 1.0, // smoothness of the luminance threshold. Range is [0, 1]
        resolutionScale: 1,
      })
    )

    renderPass.renderToScreen = false
    //noisePass.renderToScreen = false
    //bloomPass.renderToScreen = false
    //huePass.renderToScreen = false
    smaaPass.renderToScreen = true

    composer.addPass(renderPass)
    //composer.addPass(bloomPass)
    //composer.addPass(noisePass)
    //composer.addPass(huePass)
    composer.addPass(smaaPass)

    return composer
  }, [])

  useEffect(() => void composer.setSize(size.width, size.height), [size])
  return useFrame((_, delta) => composer.render(delta), 1)
}
