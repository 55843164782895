import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import usePortal from './utils/usePortal'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 */

const Modal = styled.div`
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  z-index: 3500;
  position: relative;
`
const ModalContent = styled.div`
  position: fixed;
  overflow-y: auto;
  min-height: ${({ theme }) => theme.vh(100)};
  width: calc(100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Portal = ({ id, children }) => {
  const target = usePortal(id)
  const breakOutRef = useRef(null)
  useEffect(() => {
    if (breakOutRef && breakOutRef.current) {
      disableBodyScroll(breakOutRef.current, { reserveScrollBarGap: true })
    }
    return () => {
      clearAllBodyScrollLocks()
    }
  })
  return createPortal(
    <Modal>
      <ModalContent ref={breakOutRef}>{children}</ModalContent>
    </Modal>,
    target
  )
}

export default Portal
