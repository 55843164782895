import { Color } from 'three'

const random = (base) => {
	if (Array.isArray(base))
		return Math.random() * (base[1] - base[0]) + base[0]
	return Math.random() * base
}
const pickRandom = (arr) => {
	if (Array.isArray(arr)) return arr[Math.floor(Math.random() * arr.length)]
	return arr
}
function lerp(current, target, speed = 0.1, limit = 0.001) {
	let change = (target - current) * speed
	if (Math.abs(change) < limit) {
		change = target - current
	}
	return change
}

const tempColor = new Color()
const colorToLinear = (color) => {
	tempColor.set(color).convertSRGBToLinear().toArray()
	return tempColor
}
const colorToLinearArr = (color) => {
	return tempColor.set(color).convertSRGBToLinear().toArray()
}

export { colorToLinear, colorToLinearArr, random, pickRandom, lerp }
