import React, { Suspense } from 'react'
import * as THREE from 'three'
import { Canvas } from 'react-three-fiber'
import LightSticks from './Three/LightSticks'
import distortion from './Three/Shaders/Distortion'
import Post from './Three/Effects/Post'
import { colorToLinear } from './utils/utils'

const options = {
  count: 100,
  depth: 500,
  width: 20,
  roadWidth: 9,
  roadSections: 3,
  islandWidth: 2,
  nPairs: 50,
  distortion,
  placement: [0, 150, -60],
  lightStickCount: 175,
  lightStickWidth: [1, 1.2],
  lightStickHeight: [50, 100],
  colors: [
    '#FC9D9A',
    '#055d81',
    '#4DBCE9',
    '#055D81',
    '#4DBCE9',
    '#055D81',
    '#4DBCE9',
    '#055D81',
    '#BADCEF',
  ],
}

const Scene = ({ inView }) => {
  return (
    <Canvas
      shadowMap
      pixelRatio={window.devicePixelRatio}
      gl={{ antialias: false, alpha: true }}
      camera={{ fov: 60, position: [0, -30, -60], near: 0.1, far: 1000 }}
      onCreated={({ gl }) => {
        gl.toneMapping = THREE.ACESFilmicToneMapping
        gl.outputEncoding = THREE.sRGBEncoding
        //gl.setClearColor(colorToLinear('#022938'))
      }}
      invalidateFrameloop={!inView}
    >
      <Suspense fallback={null}>
        <group rotation={[0.2, Math.PI, 0]} position={options.placement}>
          <LightSticks
            options={{ ...options }}
            speed={60}
            position={[0, 0, 0]}
          />
        </group>
        <Post />
      </Suspense>
    </Canvas>
  )
}

export default Scene
