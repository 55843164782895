import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import MissingPage from '../pages/404Page'
import { ProjectRouter } from '../pages/ProjectRouter'

import FrontPage from '../pages/FrontPage'
import CvPage from '../pages/CvPage'
import AboutPage from '../pages/AboutPage'
import Portal from './Portal'

const ModalSwitch = (props) => {
  let location = useLocation()
  let background = location.state && location.state.background

  return (
    <div>
      <Switch location={background || location}>
        <Route exact path={'/'} children={<FrontPage />} />
        <Route path={'/projects/:id'} children={<ProjectRouter />} />
        <Route path={'/cv'} children={<CvPage />} />
        <Route path={'/about'} children={<AboutPage />} />

        <Route children={<MissingPage />} />
      </Switch>
      {background && (
        <Switch>
          <Route
            path="/projects/:id"
            children={
              <Portal id="modal">
                <ProjectRouter />
              </Portal>
            }
          />
          <Route
            path="/about"
            children={
              <Portal id="modal">
                <AboutPage />
              </Portal>
            }
          />
          <Route
            path="/cv"
            children={
              <Portal id="modal">
                <CvPage />
              </Portal>
            }
          />
          <Route
            children={
              <Portal id="modal">
                <MissingPage />
              </Portal>
            }
          />
        </Switch>
      )}
    </div>
  )
}

export default ModalSwitch
