import styled from 'styled-components'

const Col = styled.div`
  width: 50%;
  ${(props) =>
    props.full &&
    `
    width: 100%
  `};
  padding: 0 2rem;
  ${({ theme }) => theme.breakpoints.md`
     width: 100%;
  `}
`

export default Col
