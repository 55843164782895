import React from 'react'
import styled from 'styled-components'
import { config, useSpring, animated } from 'react-spring'

const RaabyeMask = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  margin-left: -0.02em;
`

const RaabyeWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-transform: uppercase;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.primary};
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 4px;
  font-size: 300px;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: .6em;
  white-space: nowrap;
  letter-spacing: 0;
  .raabye-span {
    display: block;
    line-height: 1;
    position: relative;
  }
  .raabye-a-1-mask {
    transform-origin:center center;
    transform: skewX(-21deg);
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.secondary};
    -webkit-text-stroke-width: 4px;
    margin-left: -.01em;
    margin-right: -.25em;
    left: -.2em;
  }
  .raabye-a-1 {
    transform-origin:center center;
    
    transform: skewX(21deg);
    left: .2em;
  }
  .raabye-a-2-mask {
    margin-right: -.001em;
    display: inline-block;
  }
  .raabye-y-4-mask {
    margin-left: -.06em;
  }
  ${({ theme }) => theme.breakpoints.xl`
    font-size: 250px;
  `}
  ${({ theme }) => theme.breakpoints.lg`
    font-size: 150px;
    -webkit-text-stroke-width: 3px;
    .raabye-a-1-mask {
      -webkit-text-stroke-width: 3px;
    }
  `}
  ${({ theme }) => theme.breakpoints.md`
    font-size: 150px;
   -webkit-text-stroke-width: 2px;
    .raabye-a-1-mask {
      -webkit-text-stroke-width: 2px;
    }
  `}
  ${({ theme }) => theme.breakpoints.sm`
    width: 80%;
    font-size: 150px;
  `}
  ${({ theme }) => theme.breakpoints.xs`
    font-size: 80px;
    width: 100%;
    -webkit-text-stroke-width: 1px;
    .raabye-a-1-mask {
      -webkit-text-stroke-width: 1px;
    }
  `}
}`

const Raabye = React.forwardRef(({ hide, ...rest }, ref) => {
  const text = 'raabye'
  const textSplit = text.split('')

  const { x } = useSpring({
    to: { x: hide ? 1 : 0 },
    config: { ...config.stiff },
  })

  return (
    <RaabyeWrapper ref={ref}>
      {textSplit.map((item, i) => (
        <RaabyeMask
          key={`raabye-${item}-${i}`}
          className={`raabye-mask raabye-${item}-mask raabye-${item}-${i}-mask`}
        >
          {i < 3 ? (
            <animated.div
              style={{
                opacity: x.interpolate({
                  range: [0, 0.9, 1],
                  output: [1, 1, 0],
                }),
                lineHeight: x.interpolate({
                  range: [0, 1],
                  output: [1, 0],
                }),
              }}
              className={`raabye-span raabye-${item} raabye-${item}-${i}`}
            >
              {item}
            </animated.div>
          ) : (
            <div className={`raabye-span raabye-${item} raabye-${item}-${i}`}>
              {item}
            </div>
          )}
        </RaabyeMask>
      ))}
    </RaabyeWrapper>
  )
})

export default Raabye
