import React from 'react'
import styled from 'styled-components'
import { contentBaseCss, contentProps } from './contentProps'

const Paragraph = styled.p.attrs(contentProps)`
  ${contentBaseCss};
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSizes.normal};
`

export default Paragraph
