import React, { useRef } from 'react'
import styled from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'

import Header from './Header'

const ProjectCardWrapper = styled.div`
  width: 50vw;
  position: relative;
  ${({ theme }) => theme.breakpoints.sm`
    width: 60vw;
  `}
`

const ProjectCardHeader = styled(Header)`
  cursor: pointer;
`

const ProjectCard = (props) => {
  const ref = useRef(null)
  let location = useLocation()
  let history = useHistory()

  const { id, ...rest } = props

  const handleClick = (e) => {
    e.preventDefault()
    history.push({
      pathname: `/projects/${id}`,
      state: {
        background: location,
        from: ref.current.getBoundingClientRect(),
      },
    })
  }

  return (
    <ProjectCardWrapper {...props}>
      <ProjectCardHeader
        {...rest}
        onClick={handleClick}
        ref={ref}
        isCard={true}
      />
    </ProjectCardWrapper>
  )
}

export default ProjectCard
