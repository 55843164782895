import React from 'react'
import styled from 'styled-components'
import Content from './Content'
import Col from './content/Col'

const SkeletonIntro = styled.div`
  width: 100%;
  height: 2.5rem;
  background: ${({ theme }) =>
    `linear-gradient(30deg, ${theme.colors.bg} 10%, ${theme.colors.bg2})`};
  margin-bottom: 0.5rem;
`

const SkeletonBlock = styled.div`
  width: 100%;
  height: 1.5rem;
  background: ${({ theme }) =>
    `linear-gradient(30deg, ${theme.colors.bg} 0%, ${theme.colors.bg2})`};
  ${({ theme }) => theme.rhythmMargins};
`

const SkeletonImage = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background: ${({ theme }) =>
    `linear-gradient(30deg, ${theme.colors.bg} 0%, ${theme.colors.bg2})`};
  ${({ theme }) => theme.rhythmMargins};
`

const Skeleton = (props) => {
  return (
    <Content>
      <SkeletonIntro />
      <SkeletonIntro style={{ marginBottom: '2.5rem' }} />
      <Col>
        <SkeletonBlock />
        <SkeletonImage />
        <SkeletonBlock />
        <SkeletonBlock />
      </Col>
    </Content>
  )
}

export default Skeleton
