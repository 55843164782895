import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import { rgba } from 'polished'
import ModalSwitch from './components/ModalSwitch'
import Theme from './components/Theme'

const GlobalStyle = createGlobalStyle`	
	*,
	*:after,
	*:before {
		box-sizing: border-box;
	}

	html,
	body,
	#root {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		font-size: 20px;
    ${({ theme }) => theme.breakpoints.sm`
      font-size:16px;
    `}
		line-height: ${(props) => props.theme.lineHeight};
		letter-spacing: 0.1em;
    min-height: ${({ theme }) => theme.vh(100)};
	}
	body {
		background: ${({ theme }) => theme.colors.bg};
		color: ${({ theme }) => theme.colors.white};
		font-family: ${({ theme }) => theme.font};
    font-weight: ${({ theme }) => theme.fontWeight};
    min-height: ${({ theme }) => theme.vh(100)};
	}
	::selection {
		background-color:  ${({ theme }) => rgba(theme.colors.secondary, 0.8)};
		color:  ${({ theme }) => theme.colors.bg2};
		-webkit-text-stroke-color:  ${({ theme }) => theme.colors.bg2};
	}
	canvas {
		-webkit-touch-callout: none;
		user-select: none;
		overflow: hidden;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	a,
	img,
	blockquote,
	ul {
    ${({ theme }) => theme.rhythmMargins};
	}
	a {
		color: ${({ theme }) => theme.colors.secondary};
		text-decoration: none;
    font-family:  ${({ theme }) => theme.fontSecondary};
    font-weight: ${({ theme }) => theme.fontWeightSecondary};
	}
  small {
    font-size: .7em;
    letter-spacing: 0;
  }
	button {
		background: transparent;
		padding: 0;
		color:  ${({ theme }) => theme.colors.secondary};
		text-decoration: underline;
		border: 0;
    cursor: pointer;
    font-family:  ${({ theme }) => theme.fontSecondary};
    font-weight: ${({ theme }) => theme.fontWeightSecondary};
	}
  em {
    font-style: normal;
    font-weight: inherit;
    color:  ${({ theme }) => theme.colors.secondary};
  }
	img {
		width: 100%;
		height: auto;
	}
`

const App = () => {
  return (
    <Router>
      <Theme>
        <GlobalStyle />
        <ModalSwitch />
      </Theme>
    </Router>
  )
}

export default App
