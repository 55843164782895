import React from 'react'
import styled from 'styled-components'
import { animated, useSpring } from 'react-spring'

const topPos = '5vmin'
const leftPos = '10vmin'

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 64vmin;
  ${(props) => props.small && 'height: 50vmin;'}
  ${({ theme }) => ({ ...theme.rhythmMargins })};
`

const HeaderImage = styled.div`
  position: absolute;
  top: ${topPos};
  left: ${leftPos};
  right: 0;
  bottom: 0;
  background: ${(props) =>
    `${props.theme.colors.bg2} url(${props.image}) center center no-repeat`};
  background-size: cover;
`
const HeaderTitle = styled.h1`
  position: absolute;
  top: ${(props) => (props.bottom ? 'auto' : '0')};
  right: 0;
  bottom: ${(props) => (!props.bottom ? 'auto' : '0')};
  left: 0;
  text-align: left;
  max-width: 33rem;

  line-height: 1;
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSizes.xlarge};

  ${({ theme }) => theme.breakpoints.sm`
      font-size: 2.5rem;
   `}
  ${({ theme }) => theme.breakpoints.xs`
      font-size: 1.8rem;
   `}

  font-weight: ${({ theme }) => theme.fontWeightBold};
  text-transform: uppercase;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: ${({ theme }) => theme.strokeWidth};
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.primary};
  -webkit-background-clip: text;
  ${(props) =>
    props.revert &&
    `
		color: ${props.theme.colors.white};
		-webkit-text-fill-color: ${props.theme.colors.white};
		-webkit-text-stroke-color: ${props.theme.colors.white};
		`}
`
const HeaderMask = styled.div`
  position: absolute;
  top: ${topPos};
  left: ${leftPos};
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  > div {
    position: absolute;
    top: -${topPos};
    left: -${leftPos};
    right: 0;
    bottom: 0;
  }
`

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const trans1 = (x, y) => `translate3d(${x / 50}px,${y / 20}px,0)`
const trans2 = (x, y) => `translate3d(${x / 35}px,${y / 50}px,0)`

const Header = React.forwardRef(
  ({ image, title, bottom, isCard, ...rest }, ref) => {
    const [aProps, set] = useSpring(() => ({
      xy: [0, 0],
      config: { mass: 10, tension: 550, friction: 140 },
    }))

    if (isCard) {
      return (
        <animated.div style={{ transform: aProps.xy.interpolate(trans1) }}>
          <HeaderWrapper
            className={'header-wrapper'}
            {...rest}
            ref={ref}
            bottom={bottom}
            onMouseMove={({ clientX: x, clientY: y }) =>
              set({ xy: calc(x, y) })
            }
          >
            <HeaderImage
              className={'header-image'}
              image={image}
              bottom={bottom}
            />
            <HeaderTitle className={'header-title'} bottom={bottom}>
              <animated.div
                style={{ transform: aProps.xy.interpolate(trans2) }}
              >
                {title}
              </animated.div>
            </HeaderTitle>
            <HeaderMask className={'header-mask'} bottom={bottom}>
              <div>
                <HeaderTitle
                  className={'header-title header-title-revert'}
                  bottom={bottom}
                  revert
                >
                  <animated.div
                    style={{ transform: aProps.xy.interpolate(trans2) }}
                  >
                    {title}
                  </animated.div>
                </HeaderTitle>
              </div>
            </HeaderMask>
          </HeaderWrapper>
        </animated.div>
      )
    }
    return (
      <HeaderWrapper
        className={'header-wrapper'}
        {...rest}
        ref={ref}
        bottom={bottom}
      >
        <HeaderImage className={'header-image'} image={image} bottom={bottom} />
        <HeaderTitle className={'header-title'} bottom={bottom}>
          {title}
        </HeaderTitle>
        <HeaderMask className={'header-mask'} bottom={bottom}>
          <div>
            <HeaderTitle
              className={'header-title header-title-revert'}
              bottom={bottom}
              revert
            >
              {title}
            </HeaderTitle>
          </div>
        </HeaderMask>
      </HeaderWrapper>
    )
  }
)

export default Header
