import React from 'react'
import styled from 'styled-components'

const BigTextMask = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  margin-left: -0.02em;
`

const BigTextWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-transform: uppercase;
  -webkit-text-stroke-color: ${({ theme }) => theme.colors.primary};
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 4px;
  font-size: 300px;
  padding: 0;
  font-weight: ${({ theme }) => theme.fontWeightBold};
  height: .6em;
  white-space: nowrap;
  letter-spacing: 0;
  .bigtext-span {
    display: block;
    line-height: 1;
    position: relative;
  }
  .t-404-0-1-mask {
    transform-origin:center center;
    transform: skewX(-21deg);
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.secondary};
    -webkit-text-stroke-width: 4px;
    margin-left: -.01em;
    margin-right: -.4em;
    left: -.2em;
  }
  .t-loading-a-2-mask {
    transform-origin:center center;
    transform: skewX(-21deg);
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.secondary};
    -webkit-text-stroke-width: 4px;
    margin-left: -.01em;
    margin-right: -.25em;
    left: -.2em;
  }
  
  ${({ theme }) => theme.breakpoints.xl`
    font-size: 250px;
  `}
  ${({ theme }) => theme.breakpoints.lg`
    font-size: 150px;
    -webkit-text-stroke-width: 3px;
    .t-loading-a-2-mask,
    .t-404-0-1-mask {
      -webkit-text-stroke-width: 3px;
    }
  `}
  ${({ theme }) => theme.breakpoints.md`
    font-size: 150px;
   -webkit-text-stroke-width: 2px;
    .t-loading-a-2-mask,
    .t-404-0-1-mask{
      -webkit-text-stroke-width: 2px;
    }
  `}
  ${({ theme }) => theme.breakpoints.sm`
    width: 80%;
    font-size: 150px;
  `}
  ${({ theme }) => theme.breakpoints.xs`
    font-size: 80px;
    width: 100%;
    -webkit-text-stroke-width: 1px;
    .t-loading-a-2-mask,
    .t-404-0-1-mask {
      -webkit-text-stroke-width: 1px;
    }
  `}
}`

const BigText = React.forwardRef(({ text = 'bigtext', rest }, ref) => {
  const textString = text
  const textSplit = textString.split('')

  return (
    <BigTextWrapper ref={ref} {...rest} className={`t-${text}`}>
      {textSplit.map((item, i) => (
        <BigTextMask
          key={`bigtext-${text}-${item}-${i}`}
          className={`bigtext-mask t-${text}-mask t-${text}-${item}-mask t-${text}-${item}-${i}-mask`}
        >
          <div
            className={`bigtext-span t-${text}-span t-${text}-${item} t-${text}-${item}-${i}`}
          >
            {item}
          </div>
        </BigTextMask>
      ))}
    </BigTextWrapper>
  )
})

export default BigText
