import React from 'react'
import { ThemeProvider } from 'styled-components'
import media from './ThemeMediaQueries'
import vh from './ThemeVh'

const theme = {
  colors: {
    bg: '#022938',
    bg2: '#033649',
    frame: '#011c25',
    primary: '#3eaaf1',
    secondary: '#fc9d9a',
    secondaryHover: '#ff7771',
    dark: '#055d81',
    white: 'white',
  },
  font: "'Roboto', 'Trebuchet MS', Tahoma, sans-serif;",
  fontWeight: 300,
  fontWeightBold: 900,
  fontSecondary:
    "'Noto Serif','Playfair Display', Georgia, 'Times New Roman', serif;",
  fontWeightSecondary: 'normal',
  fontSizes: {
    xsmall: '0.7rem',
    small: '0.8rem',
    normal: '1rem',
    medium: '1.5rem',
    large: '3rem',
    xlarge: '4rem',
  },
  lineHeight: 1.5,
  strokeWidth: '0.2vmin',
  rhythmMargins: {
    marginTop: '0',
    marginBottom: '1.8rem',
  },
  contentMax: '35rem',
}

const Theme = ({ children }) => (
  <ThemeProvider theme={{ ...theme, breakpoints: { ...media }, vh }}>
    {children}
  </ThemeProvider>
)

export default Theme
