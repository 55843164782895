import React from 'react'
import BigText from '../components/BigText'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Button from '../components/content/Button'
import Socials from '../components/Socials'

const PageWrapper = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.bg};
  padding: 4.25rem 2rem;
  min-height: ${({ theme }) => theme.vh(100)};
`

const RaabyeWrapper = styled.div`
  position: fixed;
  min-height: ${({ theme }) => theme.vh(100)};
  top: 0;
  left: 2rem;
  right: 2rem;
  width: calc(100% - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const MissingPage = (props) => {
  let history = useHistory()
  const handler = (e) => {
    history.push('/')
  }
  return (
    <PageWrapper>
      <RaabyeWrapper>
        <BigText text={'404'} />
        <p>
          Go back to the <Button handler={handler}>front page?</Button>
        </p>
        <Socials />
      </RaabyeWrapper>
    </PageWrapper>
  )
}

export default MissingPage
