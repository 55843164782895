import React from 'react'
import styled from 'styled-components'
import { contentBaseCss } from './content/contentProps'

const items = [
  {
    year: '2016-2021',
    place: 'Geelmuyden Kiese',
    position: 'Web developer',
    desc:
      'Developing websites and digital solutions for clients like Manpower, Samsung, Arbeidstilsynet & Elkjøp.',
  },
  {
    year: '2013-2015',
    place: 'DDB Oslo',
    position: 'Graphic designer & web developer',
    desc:
      'Working with clients like McDonalds, Pepsi Max, SAS & Peugeot. Designing for digital platforms and developing websites',
  },
  {
    year: '2011-2012',
    place: 'Griffith University,\n Gold Coast',
    position: 'Digital Media',
    desc: 'Education',
  },
  {
    year: '2009-2011',
    place: 'Norges Kreative Fagskole',
    position: 'Graphic design',
    desc: 'Education',
  },
  {
    year: '2002',
    place: 'First line of code is written',
    position: '',
    desc: 'Beginning of the hackerman-journey',
  },
  {
    year: '1988',
    place: 'Born',
    position: '',
    desc: 'Future hackerman',
  },
]

const TimelineWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`

const TimelineLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 1px;
  height: 100%;
  background: white;
  ${({ theme }) => theme.breakpoints.xs`
    margin-left: 0;
  `}
`

const TimelineItemYear = styled.h3`
  font-size: 1.2rem;
  white-space: nowrap;
  transform: rotate(270deg) translateX(-50%);
  transform-origin: 0 0;
  position: absolute;
  font-weight: ${({ theme }) => theme.fontWeightSecondary};
  top: 50%;
  left: 0;
  margin-left: -0.7rem;
  background: ${(props) => props.theme.colors.bg};
  padding: 0 0.5rem;
`

const TimelineItem = styled.div`
  ${contentBaseCss};
  position: relative;
  width: 50%;
  font-family: ${(props) => props.theme.fontSecondary};
  line-height: 1;
  display: block;
  margin: 3rem 0 7rem 50%;
  padding: 0 1.5rem;
  ${({ theme }) => theme.breakpoints.xs`
    width: 100%;
    margin-left: 0;
    padding: 0 0 0 2rem;
  `}
  &:nth-child(2n + 1) {
    padding-left: 0;
    ${({ theme }) => theme.breakpoints.xs`
      padding: 0 0 0 2rem;
      text-align: left;
      margin-right: 0;
      margin-left: 0;
    `}
    text-align: right;
    margin-left: auto;
    margin-right: 50%;
    p {
      margin-left: auto;
    }
    ${TimelineItemYear} {
      left: 100%;
      margin-left: -0.7rem;
      ${({ theme }) => theme.breakpoints.xs`
        left: 0;
      `}
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const TimelineItemContent = styled.div`
  padding: 0.5rem;
  ${({ theme }) => theme.breakpoints.xs`
      padding: 0;
    `}
  letter-spacing: 0.15em;
  h4 {
    color: ${(props) => props.theme.colors.secondary};
    margin-bottom: 1rem;
    font-weight: ${({ theme }) => theme.fontWeightSecondary};
    white-space: pre-line;
    line-height: 0.9;
    font-size: 4vw;
    ${({ theme }) => theme.breakpoints.xs`
      font-size: 7vw;
    `}
  }
  h6 {
    font-family: ${(props) => props.theme.font};
    margin-bottom: 0.7rem;
    font-size: 1.2rem;
    font-weight: ${({ theme }) => theme.fontWeight};
  }
  p {
    margin-bottom: 0.5rem;
    font-family: ${(props) => props.theme.font};
    font-size: ${(props) => props.theme.fontSizes.xsmall};
    line-height: ${(props) => props.theme.lineHeight};
    max-width: 20rem;
  }
  & :last-child {
    margin-bottom: 0;
  }
`

const Timeline = (props) => {
  return (
    <TimelineWrapper>
      <TimelineLine />
      {items.map((item, i) => (
        <TimelineItem key={'timeline' + i}>
          <TimelineItemYear>{item.year}</TimelineItemYear>
          <TimelineItemContent>
            <h4>{item.place}</h4>
            <h6>{item.position}</h6>
            {item.desc && <p>{item.desc}</p>}
          </TimelineItemContent>
        </TimelineItem>
      ))}
    </TimelineWrapper>
  )
}

export default Timeline
