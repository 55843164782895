import React from 'react'
import styled from 'styled-components'
import { contentBaseCss, contentProps } from './contentProps'

const Subheading = styled.h3.attrs(contentProps)`
  ${contentBaseCss};
  margin-bottom: 0.2em;
  font-family: ${(props) => props.theme.fontSecondary};
  font-weight: ${(props) => props.theme.fontWeightSecondary};
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.secondary};
`

export default Subheading
