import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import throttle from 'lodash/fp/throttle'

const throttleMs = 75

const TallOuterContainer = styled.div.attrs(({ dynamicHeight }) => ({
  style: { height: `${dynamicHeight}px` },
}))`
  position: relative;
  width: 100%;
`

const StickyInnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: ${({ theme }) => theme.vh(100)};
  width: 100%;
  overflow-x: hidden;
`

const HorizontalTranslateContainer = styled(animated.div)`
  position: absolute;
  height: 100%;
  will-change: transform;
`

const calcDynamicHeight = (objectWidth) => {
  const vw = window.innerWidth
  const vh = window.innerHeight
  return objectWidth - vw + vh + 150
}

const handleDynamicHeight = (ref, setDynamicHeight) => {
  const objectWidth = ref.current.scrollWidth
  const dynamicHeight = calcDynamicHeight(objectWidth)
  setDynamicHeight(dynamicHeight)
}

const HorizontalScroll = ({ children }) => {
  const [dynamicHeight, setDynamicHeight] = useState(null)
  const [aProps, aSet, aStop] = useSpring(() => ({
    transform: `translateX(0px)`,
  }))

  const containerRef = useRef(null)
  const objectRef = useRef(null)

  const resizeHandler = throttle(throttleMs, () => {
    handleDynamicHeight(objectRef, setDynamicHeight)
  })

  const scrollHandler = throttle(throttleMs, () => {
    if (containerRef && containerRef.current) {
      const offsetTop = -containerRef.current.offsetTop
      aSet({ transform: `translateX(${offsetTop}px)` })
    }
  })

  useEffect(() => {
    handleDynamicHeight(objectRef, setDynamicHeight)
    window.addEventListener('resize', resizeHandler)
    window.addEventListener('scroll', scrollHandler)
    return () => {
      aStop()
      window.removeEventListener('resize', resizeHandler)
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return (
    <TallOuterContainer dynamicHeight={dynamicHeight}>
      <StickyInnerContainer ref={containerRef}>
        <HorizontalTranslateContainer style={aProps} ref={objectRef}>
          {children}
        </HorizontalTranslateContainer>
      </StickyInnerContainer>
    </TallOuterContainer>
  )
}

export default HorizontalScroll
