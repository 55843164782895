import React from 'react'
import styled from 'styled-components'
import Button from './content/Button'

const SocialsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${({ theme }) => theme.breakpoints.sm`
    flex-direction: column;
  `}
  a {
    line-height: 1.75;
    margin: 0.5rem;
  }
`

const Socials = (props) => {
  return (
    <SocialsWrapper>
      <Button
        href="mailto:torstein@raabye.dev"
        rel={'noopener'}
        title="torstein.raabye@gmail.com"
      >
        torstein@raabye.dev
      </Button>
      <Button
        href="https://www.linkedin.com/in/traabye/"
        rel={'noopener'}
        title="@traabye"
      >
        LinkedIn
      </Button>
      <Button
        href="https://www.github.com/raabye/"
        rel={'noopener'}
        title="@raabye"
      >
        GitHub
      </Button>
    </SocialsWrapper>
  )
}

export default Socials
