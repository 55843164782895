import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  Suspense,
} from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { projectRoutes } from './ProjectRouter'
import Scene from '../components/Scene'
import Raabye from '../components/Raabye'
import useScrollSpy from '../components/utils/useScrollSpy'
import HorizontalScroll from '../components/HorizontalScroll/HorizontalScroll'
import Navigator from '../components/Navigator'
import ProjectCard from '../components/ProjectCard'
import RaabyeBox from '../components/RaabyeBox'
import RaabyeBoxEnd from '../components/RaabyeBoxEnd'

const HorizontalSection = styled.section`
  position: relative;
  width: 100%;
  min-height: ${({ theme }) => theme.vh(100)};
  z-index: 1;
  background: linear-gradient(
    0deg,
    ${(props) => rgba(props.theme.colors.bg, 0)} 0%,
    ${(props) => rgba(props.theme.colors.bg, 0.4)} 2%,
    ${(props) => rgba(props.theme.colors.bg, 0.65)} 15%,
    ${(props) => rgba(props.theme.colors.bg, 0.65)} 95%,
    ${(props) => rgba(props.theme.colors.bg, 1)}
  );
`

const HorizontalContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`

const ProjectSlide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ theme }) => theme.vh(100)};
  flex-shrink: 0;
  min-width: 100vw;
  width: 100%;
  position: relative;
  padding: 0 65vw;
  > div {
    margin: 0 15vw;
  }
`

const RaabyeWrapper = styled.div`
  position: fixed;
  min-height: ${({ theme }) => theme.vh(100)};
  top: 0;
  left: 2rem;
  right: 2rem;
  width: calc(100% - 4rem);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  ${({ hasPassed }) =>
    hasPassed &&
    `
      position: absolute;
  `}
`

const Triggers = styled.div`
  height: 25vh;
  width: 100%;
  display: block;
  z-index: -1;
  pointer-events: none;
  visibility: hidden;
  position: relative;
`

const TriggerAfterHeader = styled(Triggers)`
  background: ${(props) => rgba(props.theme.colors.bg, 1)};
  z-index: 10;
  height: ${({ theme }) => theme.vh(5)};
  visibility: visible;
  margin-top: -1px;
`

const Mountain = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.vh(40)};
  z-index: 1;
  background: url('/mountainbg.svg') bottom center repeat-x;
  background-size: auto 100%;
  pointer-events: none;
`
const Mountain2 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.vh(62)};
  z-index: 0;
  background: url('/mountainbg2.svg') top center repeat-x;
  background-size: auto 100%;
  pointer-events: none;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 100%;
  min-height: ${({ theme }) => theme.vh(100)};
  justify-content: center;
  align-items: center;
`

const HeaderSection = styled(Section)`
  z-index: 2;
`

const FrontPage = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -45,
  })

  const executeScroll = (id, name) => {
    sectionRefs[id].current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <div>
      <Navigator executeScroll={executeScroll} activeSection={activeSection} />
      <Background>
        <Scene inView={activeSection <= 2} />
        <Mountain2 />
        <Mountain />
      </Background>

      <HeaderSection className="content" ref={sectionRefs[0]}>
        <RaabyeBox />
      </HeaderSection>
      <TriggerAfterHeader ref={sectionRefs[1]} />
      <TriggerAfterHeader ref={sectionRefs[3]} />

      <HorizontalSection>
        <HorizontalScroll>
          <HorizontalContent>
            <ProjectSlide>
              {projectRoutes.map(({ component, ...rest }) => (
                <ProjectCard key={`procard-${rest.id}`} {...rest} />
              ))}
            </ProjectSlide>
          </HorizontalContent>
        </HorizontalScroll>
      </HorizontalSection>

      <Section className="content" ref={sectionRefs[4]}>
        <RaabyeWrapper hasPassed={activeSection >= 4}>
          <Raabye hide={activeSection >= 4} />
        </RaabyeWrapper>
        <RaabyeBoxEnd show={activeSection >= 4} />
        <Triggers style={{ height: '15vh' }} />
      </Section>
    </div>
  )
}

export default FrontPage
