import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { rgba } from 'polished'
import styled from 'styled-components'

import Button from '../components/content/Button'
import Raabye from '../components/Raabye'
import About from '../components/About'

const zIndex = 4000

const Page = styled.div`
  position: relative;
  background: ${(props) => props.theme.colors.bg};
  padding: 4.25rem 4rem;
  min-height: ${({ theme }) => theme.vh(100)};
`

const Frame = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 2rem solid ${(props) => props.theme.colors.frame};
  z-index: ${zIndex + 100};
  pointer-events: none;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 2rem;
  right: 2rem;
  bottom: 85%;
  ${({ theme }) => theme.breakpoints.xs`
       bottom: 75%;
 `}
  background: linear-gradient(
    0deg,
    ${(props) => rgba(props.theme.colors.bg, 0.5)} 50%,
    ${(props) => rgba(props.theme.colors.bg, 1)}
  );
  @media screen and (max-height: 300px) {
    bottom: 75%;
    background: linear-gradient(
      0deg,
      ${(props) => rgba(props.theme.colors.bg, 0)} 50%,
      ${(props) => rgba(props.theme.colors.bg, 1)}
    );
  }
  z-index: ${zIndex + 90};
  pointer-events: none;
`
const RaabyeFixed = styled.div`
  position: fixed;
  top: 20%;
  left: 2rem;
  right: 2rem;
  ${({ theme }) => theme.breakpoints.xs`
       top: 25%;
  `}
  @media screen and (max-height: 400px) {
    position: relative;
    top: 0;
    margin-bottom: 1.8rem;
  }
  width: calc(100% - 4rem);

  z-index: ${zIndex + 95};
  transform: translateY(-50%);
  background: linear-gradient(
      0deg,
      ${(props) => rgba(props.theme.colors.bg, 0)},
      ${(props) => props.theme.colors.bg} 10%,
      ${(props) => props.theme.colors.bg} 90%,
      ${(props) => rgba(props.theme.colors.bg, 0.5)}
    )
    bottom;
  background-size: 100% 100%;
  pointer-events: none;
`

const CloseButton = styled(Button)`
  font-size: ${(props) => props.theme.fontSizes.normal};
  display: block;
  margin: 3rem auto 0;
  z-index: ${zIndex + 100};
  ${(props) =>
    props.fixed &&
    `
      position: absolute;
      top: 0;
      right: 2.2rem;
      text-align: right;
      margin: .5rem 0;
      z-index: ${zIndex + 105};
      font-size: ${props.theme.fontSizes.small};
  `}
`
const Spacer = styled.div`
  height: calc(20vh + 4.25rem);
  width: 100%;
  ${({ theme }) => theme.breakpoints.xs`
       height: calc(30vh + 4.25rem);
 `};
  @media screen and (max-height: 400px) {
    height: 0;
  }
`

const AboutPage = (props) => {
  let history = useHistory()
  let location = useLocation()
  let background = location.state && location.state.background

  const escHandler = useCallback((event) => {
    if (event.keyCode === 27) {
      back(event)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escHandler, false)
    return () => {
      document.removeEventListener('keydown', escHandler, false)
    }
  }, [])

  let back = (e) => {
    e.stopPropagation()
    if (background) {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  return (
    <Page>
      <Frame />
      <CloseButton fixed="true" handler={back}>
        Close
      </CloseButton>
      <Overlay />
      <RaabyeFixed>
        <Raabye />
      </RaabyeFixed>
      <Spacer />
      <About />
      <CloseButton handler={back}>Go back</CloseButton>
    </Page>
  )
}

export default AboutPage
