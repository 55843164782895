import * as THREE from 'three'
import React, { useRef, useMemo } from 'react'
import { useFrame } from 'react-three-fiber'
import { random, pickRandom, colorToLinearArr } from '../utils/utils'
import './Shaders/LightSticks'

const tempObject = new THREE.Object3D()

const LightSticks = ({ options, color, speed, ...props }) => {
	const model = useRef()
	let stickoffset = options.depth / options.lightStickCount
	const colors = new Array(options.lightStickCount)
		.fill()
		.map(() => pickRandom(options.colors))
	const aColor = useMemo(
		() =>
			Float32Array.from(
				new Array(options.lightStickCount)
					.fill()
					.flatMap((_, i) => colorToLinearArr(colors[i]))
			),
		[]
	)

	let aOffset = useMemo(
		() =>
			Float32Array.from(
				new Array(options.lightStickCount)
					.fill()
					.flatMap((aOffset = [], i) => {
						aOffset.push((i - 1) * stickoffset + Math.random())
						return aOffset
					})
			),
		[options.lightStickCount]
	)

	let aMetrics = useMemo(
		() =>
			Float32Array.from(
				new Array(options.lightStickCount)
					.fill()
					.flatMap((aMetrics = [], i) => {
						let width = random(options.lightStickWidth)
						let height = random(options.lightStickHeight)

						aMetrics.push(width)
						aMetrics.push(height)

						return aMetrics
					})
			),
		[options.lightStickCount]
	)

	const uniforms = useMemo(
		() =>
			Object.assign(
				{
					uTime: new THREE.Uniform(0),
					uTravelLength: new THREE.Uniform(options.depth),
					uSpeed: new THREE.Uniform(speed)
				},
				options.distortion.uniforms
			),
		[options.depth, speed]
	)

	useFrame((state) => {
		const t = state.clock.getElapsedTime()
		uniforms.uTime = new THREE.Uniform(t * 0.1)
		for (let i = 0; i < options.count; i++) {
			tempObject.updateMatrix()
			model.current.setMatrixAt(i, tempObject.matrix)
		}
		model.current.instanceMatrix.needsUpdate = true
	})

	return (
		<instancedMesh
			{...props}
			ref={model}
			args={[null, null, options.lightStickCount]}
			frustumCulled={false}
			onPointerOver={props.onHoverHandler}
		>
			<cylinderBufferGeometry attach='geometry' args={[0.5, 0.1, 1, 32]}>
				<instancedBufferAttribute
					attachObject={['attributes', 'aOffset']}
					args={[aOffset, 1, false]}
				/>
				<instancedBufferAttribute
					attachObject={['attributes', 'aMetrics']}
					args={[aMetrics, 2, false]}
				/>
				<instancedBufferAttribute
					attachObject={['attributes', 'aColor']}
					args={[aColor, 3, false]}
				/>
			</cylinderBufferGeometry>

			<lightSticksMaterial uniforms={uniforms} vertexColors />
		</instancedMesh>
	)
}

export default LightSticks
