import React from 'react'
import styled from 'styled-components'
import Subheading from './content/Subheading'
import Paragraph from './content/Paragraph'
import { contentBaseCss } from './content/contentProps'

const AboutWrapper = styled.div`
  max-width: ${({ theme }) => theme.contentMax};
  ${({ theme }) => theme.rhythmMargins};
  margin-left: auto;
  margin-right: auto;
`

const AboutList = styled.ul`
  list-style: none;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.5;
  ${contentBaseCss}
  ${Subheading} {
    margin-bottom: 0;
    font-size: 1.2rem;
    max-width: none;
  }
`

const AboutIntro = styled(Subheading)`
  font-size: 1.25rem;
  margin-bottom: 1.8rem;
`

const Desc = styled.ul`
  list-style: none;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  line-height: 1.5;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  text-align: right;
  > li {
    display: inline-block;
    margin-right: 1em;
  }
  ${contentBaseCss}
  ${({ theme }) => theme.breakpoints.sm`
      text-align: center;
  `}
`
const Desc1 = styled.span`
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  margin-right: 0.15em;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.secondary};
`

const Desc2 = styled.span`
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  margin-right: 0.15em;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.primary};
`
const Desc3 = styled.span`
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  margin-right: 0.15em;
  border-radius: 100%;
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.breakpoints.sm`
    border-width: 1px;
  `}
`

const AboutListItem = styled.li`
  position: relative;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  &:last-child {
    margin-bottom: 0;
  }
  ${Desc1},${Desc2},${Desc3} {
    margin-right: 0.5em;
    height: 0.6em;
    width: 0.6em;
  }
`

const About = (props) => {
  return (
    <AboutWrapper>
      <AboutIntro>
        I’m a 32 years old norwegian full stack javascript web developer, with a
        graphic design&nbsp;background.
      </AboutIntro>
      <Paragraph>
        At the age of 14 I went down a coding rabbit hole, and it felt comfy. In
        black and white old-school Notepad writing inline font-tags, tables and
        img-tags filled with gifs and ascii-like imagery (aah good times),
        creating simple websites. After 3,5 years of education in graphic design
        and digital media, adding another 8 years work experience, I emerged
        from the rabbit hole as a full stack developer.
      </Paragraph>
      <Paragraph>
        My main stack is <em>reactJs, nodeJs and mongoDB</em>, but I have and
        can work with many other stacks and things, listed below.
      </Paragraph>

      <Desc>
        <li>Skills by color:</li>
        <li>
          <Desc1 />
          =Best
        </li>
        <li>
          <Desc2 />
          =Decent
        </li>
        <li>
          <Desc3 />
          =Familiar
        </li>
      </Desc>

      <AboutList>
        <AboutListItem>
          <Subheading>Languages</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          Javascript
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          HTML/CSS
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          PHP
        </AboutListItem>
      </AboutList>

      <AboutList>
        <AboutListItem>
          <Subheading>Platforms</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          nodeJs
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          Wordpress
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          CraftCms
        </AboutListItem>
      </AboutList>

      <AboutList>
        <AboutListItem>
          <Subheading>Frameworks</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          reactJs
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          nextJs
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          socketIO
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          vueJs
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          PhaserIO
        </AboutListItem>
        <AboutListItem>
          <Desc3 />
          ThreeJS
        </AboutListItem>
        <AboutListItem>
          <Desc3 />
          angular
        </AboutListItem>
      </AboutList>

      <AboutList>
        <AboutListItem>
          <Subheading>Databases</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          mongoDB
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          Firebase
        </AboutListItem>
        <AboutListItem>
          <Desc3 />
          DynamoDB(AWS)
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          SQL
        </AboutListItem>
      </AboutList>

      <AboutList>
        <AboutListItem>
          <Subheading>Services</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          Heroku
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          AWS (S3, Elastic Beanstalk and Lambda)
        </AboutListItem>
        <AboutListItem>
          <Desc3 />
          Netlify
        </AboutListItem>
      </AboutList>

      <AboutList>
        <AboutListItem>
          <Subheading>Other tech</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          GIT
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          RaspberryPi
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          IoT
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          Build computers
        </AboutListItem>
      </AboutList>

      <AboutList>
        <AboutListItem>
          <Subheading>Programs</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          WebStorm
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          VsCode
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          Figma
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          Sketch
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          Photoshop
        </AboutListItem>
      </AboutList>

      <AboutList>
        <AboutListItem>
          <Subheading>Beverages</Subheading>
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          Coffee
        </AboutListItem>
        <AboutListItem>
          <Desc1 />
          Hibiki
        </AboutListItem>
        <AboutListItem>
          <Desc2 />
          Lagavulin
        </AboutListItem>
      </AboutList>
    </AboutWrapper>
  )
}

export default About
