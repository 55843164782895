import { css } from 'styled-components'

const contentProps = (props) => {
  return {
    textAlign: props.text ? props.text : 'left',
    contentRight: !!props.right,
    contentLeft: !!props.left,
    contentCenter: !!props.center,
    contentTop: !!props.top,
    contentMiddle: !!props.middle,
    contentBottom: !!props.bottom,
  }
}

const contentBaseCss = css`
  max-width: ${(props) => props.theme.contentMax};
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`};
  ${(props) => props.textAlign === 'right' && 'margin-left: auto;'}
  ${(props) =>
    props.textAlign === 'center' && 'margin-left: auto;margin-right: auto;'}

  ${(props) => props.contentRight && 'margin-right:0;'}
  ${(props) => props.contentCenter && 'margin-left: auto;margin-right:auto;'}
  ${(props) => props.contentLeft && 'margin-left: 0;'}
  ${(props) => props.theme.breakpoints.md`
    ${(props) =>
      props.textAlign &&
      `
      text-align: left;
      margin-left: unset;
      margin-right: unset;
    `};
  `};
`

export { contentProps, contentBaseCss }
