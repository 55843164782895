import React, { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import ProjectPage from './ProjectPage'
import MissingPage from './404Page'
import Skeleton from '../components/Skeleton'
const President = lazy(() => import('./projects/President'))
const Hk = lazy(() => import('./projects/Hk'))
const Battery = lazy(() => import('./projects/Battery'))

const projectRoutes = [
  {
    component: (props) => (
      <ProjectPage {...props}>
        <Suspense fallback={<Skeleton />}>
          <Battery />
        </Suspense>
      </ProjectPage>
    ),
    title: 'Time to switch to battery again',
    image: '/bos1.jpg',
    id: 'battery',
    bottom: true,
  },
  {
    component: (props) => (
      <ProjectPage {...props}>
        <Suspense fallback={<Skeleton />}>
          <President />
        </Suspense>
      </ProjectPage>
    ),
    title: 'Who wants to be a president?',
    image: '/president1.jpg',
    id: 'president',
  },
  {
    component: (props) => (
      <ProjectPage {...props}>
        <Suspense fallback={<Skeleton />}>
          <Hk />
        </Suspense>
      </ProjectPage>
    ),
    title: 'Who kidnapped Geelmuyden?',
    image: '/hk1.jpg',
    id: 'hk',
    small: true,
    bottom: true,
  },
]

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        <route.component
          title={route.title}
          image={route.image}
          bottom={route.bottom}
          {...props}
          routes={route.routes}
        />
      )}
    />
  )
}

const ProjectRouter = (props) => (
  <Switch>
    {projectRoutes.map((route, i) => {
      return (
        <RouteWithSubRoutes
          key={route.id}
          path={`/projects/${route.id}`}
          {...route}
        />
      )
    })}
    <Route component={MissingPage} />
  </Switch>
)

export { ProjectRouter, projectRoutes }
