import React from 'react'
import styled from 'styled-components'

const ButtonWrapper = styled.button`
  font-size: inherit;
  line-height: inherit;
  font-family: ${({ theme }) => theme.fontSecondary};
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  position: relative;
  display: inline-block;
  outline: none;
  letter-spacing: 0.02rem;
  margin-bottom: 0;
  span {
    position: relative;
    z-index: 3;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.frame};
    transition: color 250ms;
    &:before {
      height: 100%;
      transition: height 250ms;
    }
  }
  &:before {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: -0.2em;
    right: -0.2em;
    background: ${({ theme }) => theme.colors.secondary};
  }
`

const Button = ({ children, handler, href, ...rest }) => {
  if (href) {
    return (
      <ButtonWrapper as={'a'} href={href} {...rest}>
        <span>{children}</span>
      </ButtonWrapper>
    )
  }
  return (
    <ButtonWrapper onClick={handler} {...rest}>
      <span>{children}</span>
    </ButtonWrapper>
  )
}

export default Button
