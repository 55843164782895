import React from 'react'
import styled from 'styled-components'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 95rem;
  width: 85%;
  margin-left: 10%;
  margin-right: 5%;
  ${({ theme }) => theme.breakpoints.xl`
    width: calc(100% - 10vmin - 2rem);
    margin-left: calc(10vmin + 2rem);
  `}
  ${({ theme }) => theme.breakpoints.md`
    margin-left: 0;
    width: 100%;
  `}
`

const Content = (props) => {
  const { children, ...rest } = props
  return <ContentWrapper {...rest}>{children}</ContentWrapper>
}

export default Content
