import * as THREE from 'three'

const distortion = {
	uniforms: {
		uFreq: new THREE.Uniform(new THREE.Vector4(4, 8, 8, 1)),
		uAmp: new THREE.Uniform(new THREE.Vector4(25, 5, 10, 10))
	},
	getDistortion: `
        uniform vec4 uFreq;
        uniform vec4 uAmp;
        float nsin(float val){
        return sin(val) * 0.5+0.5;
        }
    
				#define PI 3.14159265358979
        float getDistortionX(float progress){
            return 
                    (
                        cos( PI * progress * uFreq.r ) * uAmp.r +
                        pow(cos(PI * progress * uFreq.g  * (uFreq.g / uFreq.r)),2. )* uAmp.g
                    
                    );
        }
        float getDistortionY(float progress){
            return 
                    (
                        -nsin( PI * progress * uFreq.b ) * uAmp.b +
                        -pow(nsin(PI * progress * uFreq.a  / (uFreq.b / uFreq.a) ),5.) * uAmp.a
                    
                    );
        }
        vec3 getDistortion(float progress){
            return vec3(
                getDistortionX(progress)-getDistortionX(0.02) ,
                getDistortionY(progress)- getDistortionY(0.02),
                0.
            );
        }
    `
}

export default distortion
