import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import Button from './content/Button'
import Raabye from './Raabye'
import { animated, config, useSpring } from 'react-spring'

const AnimatedSpan = styled(animated.span)`
  display: inline-block;
`

const RaabyeText = css`
  font-family: ${({ theme }) => theme.font};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  ${({ theme }) => theme.lineHeight}

  ${({ theme }) => theme.breakpoints.sm`
    //text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.normal};
  `}
  ${({ theme }) => theme.breakpoints.lg`
    //text-align: center;
    font-size: ${({ theme }) => theme.fontSizes.normal};
  `}
`

const RaabyePlaceHolder = styled.div`
  > div,
  .raabye-span,
  .raabye-mask {
    -webkit-text-stroke-color: transparent !important;
  }
`

const RaabyeBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: ${({ theme }) => theme.vh(100)};
`

const RaabyeBefore = styled.p`
  text-align: left;
  margin-top: 0;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  ${RaabyeText};
`

const RaabyeAfter = styled.p`
  text-align: right;
  margin-bottom: 0;
  margin-top: 1rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  ${RaabyeText};
  ${({ theme }) => theme.breakpoints.sm`
    text-align: center;
  `}
`

const RaabyeBoxInner = styled.div`
  position: relative;
`

const ButtonLink = styled(Button)``

const RaabyeBox = ({ dimensions, ...rest }) => {
  const { x } = useSpring({
    from: { x: 0 },
    to: { x: 1 },
    config: { ...config.gentle },
  })

  let location = useLocation()
  let history = useHistory()

  const cvHandler = () => {
    let cv = { pathname: '/cv', state: { background: location } }
    history.push(cv)
  }

  const stackHandler = () => {
    let about = { pathname: '/about', state: { background: location } }
    history.push(about)
  }

  return (
    <RaabyeBoxWrapper {...rest}>
      <RaabyeBoxInner>
        <RaabyePlaceHolder>
          <Raabye />
        </RaabyePlaceHolder>
        <RaabyeBefore>
          <AnimatedSpan
            style={{
              opacity: x.interpolate({
                range: [0, 0.6, 1],
                output: [0, 0, 1],
              }),
              transform: x
                .interpolate({
                  range: [0, 0.6, 1],
                  output: [20, 20, 0],
                })
                .interpolate((x) => `translateY(${x}px)`),
            }}
          >
            Hi, I'm Torstein
          </AnimatedSpan>
        </RaabyeBefore>
        <RaabyeAfter>
          <AnimatedSpan
            style={{
              opacity: x.interpolate({
                range: [0, 0.6, 1],
                output: [0, 0, 1],
              }),
              transform: x
                .interpolate({
                  range: [0, 0.6, 1],
                  output: [-20, -20, 0],
                })
                .interpolate((x) => `translateY(${x}px)`),
              config: config.wobbly,
            }}
          >
            <Button handler={stackHandler}>Full&nbsp;stack</Button> web
            developer
            <br />
            with{' '}
            <ButtonLink handler={cvHandler}>
              8&nbsp;years of&nbsp;experience
            </ButtonLink>
          </AnimatedSpan>
        </RaabyeAfter>
      </RaabyeBoxInner>
    </RaabyeBoxWrapper>
  )
}

export default RaabyeBox
