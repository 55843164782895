import React, { useState, useEffect, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { Spring } from 'react-spring/renderprops'
import Button from './content/Button'

const sections = [
  {
    id: 0,
    name: 'Home',
  },
  {
    id: 3,
    name: 'Projects',
  },
  {
    id: 4,
    name: 'Contact',
  },
]

const FrameCss = css`
  position: fixed;
  background: ${({ theme }) => theme.colors.frame};
  z-index: 1500;
`
const FrameTop = styled.div`
  ${FrameCss};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2rem;
  z-index: 0;
`

const FrameTopFixed = styled(FrameTop)`
  z-index: 3500;
  position: fixed;
`

const FrameBottom = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  height: 2rem;
  ${FrameCss};
`
const FrameLeft = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  width: 2rem;
  ${FrameCss};
`
const FrameRight = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  width: 2rem;
  ${FrameCss};
`
const Frame = styled.div``

const NavigatorLine = styled.div`
  margin: 0.4rem 0 0 0;
  width: 0.8rem;
  height: 1px;
  background: white;
  display: inline-block;
  position: relative;
`
const NavigatorLineCorner = styled.div`
  margin: 0.5rem 0;
  transform: rotate(90deg);
  width: 0.55rem;
  height: 1px;
  background: white;
  display: inline-block;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    height: 0.55rem;
    width: 1px;
    background: white;
    display: block;
    bottom: 0;
    left: 0;
  }
`

const NaviButton = styled(Button)`
  font-size: 0.8rem;
  line-height: 1.5;
  letter-spacing: 0.1rem;
  display: inline-block;
  margin: 0 0.6rem 0.05rem;
  &:before {
    background: ${({ theme }) => theme.colors.frame};
  }
  &:hover {
    &:before {
      background: ${({ theme }) => theme.colors.secondary};
    }
  }
`
const NavigatorWrapper = styled.div`
  position: fixed;
  z-index: 1750;
`
const NavigatorInner = styled.div`
  position: relative;
  line-height: 1;
`
const NavigatorInnerRotate = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  transform-origin: bottom left;
  transform: rotate(-90deg);
  height: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const NavigatorWrapperLeft = styled(NavigatorWrapper)`
  left: 0.85rem;
  bottom: 0.25rem;
  ${NavigatorInnerRotate} {
    transform-origin: bottom left;
    transform: rotate(-90deg);
  }
  ${NaviButton} {
    margin-bottom: -0.25rem;
  }
  ${NavigatorLineCorner} {
    margin: 0.8rem 0.5rem 0;
    &:after {
      left: 0;
      right: auto;
    }
  }
`

const NavigatorWrapperRight = styled(NavigatorWrapper)`
  right: 1rem;
  bottom: 0.5rem;
  ${NavigatorInnerRotate} {
    transform-origin: bottom left;
    transform: rotate(-90deg);
  }
  ${NavigatorLine} {
    margin: 0 0 0 0;
  }
  ${NavigatorLineCorner} {
    margin: 0 0.5rem 0.5rem 0.25rem;
    &:after {
      left: auto;
      right: 0;
    }
  }
`

const NavigatorWrapperCenter = styled(NavigatorWrapper)`
  left: 1.75rem;
  bottom: 0.5rem;
  right: 1.75rem;
  ${NaviButton} {
    margin-bottom: -0.25rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.secondary};
    &:hover {
      color: ${({ theme }) => theme.colors.frame};
    }
  }
  ${NavigatorInnerRotate} {
    transform-origin: bottom left;
    transform: rotate(-90deg);
  }
  ${NavigatorLineCorner} {
    margin: 0 0.5rem 0.5rem 0.25rem;
    &:after {
      left: auto;
      right: 0;
    }
  }
`

const Navigator = ({ activeSection, executeScroll }) => {
  const [sortedSections, setSortedSections] = useState({
    left: [],
    center: [],
    right: [],
  })

  const [isTop, setIsTop] = useState(false)

  useEffect(() => {
    const [center, activeIndex] = sections.reduce((prev, cur, i) => {
      if (!prev) return [cur, i]
      return cur.id <= activeSection ? [cur, i] : prev
    }, [])
    const left = sections
      .filter((section, i) => activeIndex > i)
      .sort((a, b) => b.id - a.id)
    const right = sections.filter((section, i) => activeIndex < i)

    setIsTop(activeSection > 0)
    setSortedSections({
      left,
      center,
      right,
    })
  }, [activeSection])

  return (
    <>
      <Frame>
        <FrameTop />

        <Spring
          to={{
            transform: isTop ? 'translate(0,0rem)' : 'translate(0,-2rem)',
          }}
        >
          {(props) => <FrameTopFixed style={props} />}
        </Spring>
        <FrameBottom />
        <FrameLeft />
        <FrameRight />
      </Frame>
      <NavigatorWrapperLeft>
        <NavigatorInner>
          <NavigatorInnerRotate>
            <NavigatorLineCorner />
            {sortedSections.left.map((section, i) => {
              return (
                <Fragment key={`left-${section.id}-line`}>
                  {i > 0 && i !== section.length - 1 && <NavigatorLine />}
                  <NaviButton
                    handler={() => executeScroll(section.id, section.name)}
                  >
                    <Spring
                      from={{
                        opacity: 0,
                        transform: 'translate(-10px,0)',
                        width: 0,
                      }}
                      to={{
                        opacity: 1,
                        transform: 'translate(0px,0)',
                        width: 'auto',
                      }}
                    >
                      {(props) => (
                        <div style={{ overflow: 'hidden', ...props }}>
                          {section.name}
                        </div>
                      )}
                    </Spring>
                  </NaviButton>
                </Fragment>
              )
            })}
          </NavigatorInnerRotate>
        </NavigatorInner>
      </NavigatorWrapperLeft>
      <NavigatorWrapperCenter>
        <NavigatorInner>
          <NaviButton
            key={`center-${sortedSections.center.id}`}
            handler={() =>
              executeScroll(
                sortedSections.center.id,
                sortedSections.center.name
              )
            }
          >
            <Spring
              from={{ opacity: 0, transform: 'translate(-10px,0)', width: 0 }}
              to={{ opacity: 1, transform: 'translate(0px,0)', width: 'auto' }}
            >
              {(props) => (
                <div style={{ overflow: 'hidden', ...props }}>
                  {sortedSections.center.name}
                </div>
              )}
            </Spring>
          </NaviButton>
        </NavigatorInner>
      </NavigatorWrapperCenter>
      <NavigatorWrapperRight>
        <NavigatorInner>
          <NavigatorInnerRotate>
            <NavigatorLineCorner />
            {sortedSections.right.map((section, i) => {
              return (
                <Fragment key={`right-${section.id}-line`}>
                  {i > 0 && i !== section.length - 1 && <NavigatorLine />}
                  <NaviButton
                    handler={() => executeScroll(section.id, section.name)}
                  >
                    <Spring
                      from={{
                        opacity: 0,
                        transform: 'translate(-10px,0)',
                        width: 0,
                      }}
                      to={{
                        opacity: 1,
                        transform: 'translate(0px,0)',
                        width: 'auto',
                      }}
                    >
                      {(props) => (
                        <div style={{ overflow: 'hidden', ...props }}>
                          {section.name}
                        </div>
                      )}
                    </Spring>
                  </NaviButton>
                </Fragment>
              )
            })}
          </NavigatorInnerRotate>
        </NavigatorInner>
      </NavigatorWrapperRight>
    </>
  )
}

export default Navigator
