import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Suspense,
  lazy,
} from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSpring, animated, config } from 'react-spring'
import styled from 'styled-components'

import Button from '../components/content/Button'
import Header from '../components/Header'
import Skeleton from '../components/Skeleton'
import Content from '../components/Content'

const ProjectPageWrapper = styled(animated.div)`
  position: relative;
  background: ${(props) => props.theme.colors.bg};
  padding: 4.25rem 2rem;
  min-height: ${({ theme }) => theme.vh(100)};
  overflow: hidden;
`

const Frame = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100%;
  width: 100%;
  border: 2rem solid ${(props) => props.theme.colors.frame};
  //z-index: 10;
  pointer-events: none;
  transform-origin: center center;
`
const Fader = styled(animated.div)`
  opacity: 0;
`

const CloseButton = styled(Button)`
  font-size: ${(props) => props.theme.fontSizes.normal};
  display: block;
  margin: 2rem 2rem 0;
  text-align: left;
  z-index: 10;
  ${(props) =>
    props.fixed &&
    `
      position: absolute;
      top: 0;
      right: 2.2rem;
      text-align: right;
      margin: .5rem 0;
      z-index: 15;
      font-size: ${props.theme.fontSizes.small};
  `}
`

const HeaderWrapper = styled.div`
  padding: 0 2rem;
`

const HeaderLock = styled.div`
  position: relative;
  width: 100%;
  height: 64vmin;
  //overflow: hidden;
  margin-bottom: 1.5rem;
`
const HeaderAnim = styled(animated.div)`
  position: relative;
  //overflow: hidden;
  display: block;
`

const ProjectPage = ({ title, image, bottom, children, ...props }) => {
  const toRef = useRef(null)
  const [toAnim, setToAnim] = useState({
    top: 0,
    left: 0,
    width: window.innerWidth * 0.7,
    height: window.innerHeight * 0.7,
  })
  const [open, setOpen] = useState(false)
  const [isAnimationDone, setIsAnimationDone] = useState(false)
  const [fromAnim, setFromAnim] = useState({
    top: window.innerHeight / 2,
    left: 0,
    width: 0,
    height: 0,
  })

  let history = useHistory()
  let location = useLocation()
  let background = location.state && location.state.background

  const escHandler = useCallback((event) => {
    if (event.keyCode === 27) {
      back(event)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', escHandler, false)
    setOpen(true)
    location?.state?.from && setFromAnim(location.state.from)
    return () => {
      document.removeEventListener('keydown', escHandler, false)
      setFromAnim({})
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    if (toRef?.current) setToAnim(toRef.current.getBoundingClientRect())
  }, [open])

  const { x } = useSpring({
    from: { x: setFromAnim ? 0 : 1 },
    to: { x: 1 },
    config: { ...config.default },
    onRest: () => setIsAnimationDone(true),
  })

  let back = (e) => {
    e.stopPropagation()
    if (background) {
      history.goBack()
    } else {
      history.push({
        pathname: `/`,
        state: {
          background: null,
          from: null,
        },
      })
    }
  }

  return (
    <ProjectPageWrapper
      style={{
        background: x
          .interpolate({
            range: [0, 0.5, 1],
            output: [0, 1, 1],
          })
          .interpolate((x) => `rgba(2,41,56,${x})`),
      }}
    >
      <Frame />

      <HeaderWrapper>
        <HeaderLock ref={toRef} className={open ? 'isOpen' : ''}>
          <HeaderAnim
            style={{
              position: !isAnimationDone ? 'absolute' : 'relative',
              top: x.interpolate({
                range: [0, 1],
                output: [fromAnim.top / 2, 0],
              }),
              left: x.interpolate({
                range: [0, 1],
                output: [fromAnim.left, 0],
              }),
              width: !isAnimationDone
                ? x.interpolate({
                    range: [0, 1],
                    output: [fromAnim.width, toAnim.width],
                  })
                : '',
              height: !isAnimationDone
                ? x.interpolate({
                    range: [0, 1],
                    output: [fromAnim.height, toAnim.height],
                  })
                : '',
            }}
          >
            <Header image={image} title={title} bottom={bottom} />
          </HeaderAnim>
        </HeaderLock>
      </HeaderWrapper>
      <CloseButton fixed="true" onClick={back}>
        Close
      </CloseButton>
      <Fader
        style={{
          opacity: x.interpolate({
            range: [0, 0.8, 1],
            output: [0, 0, 1],
          }),
          transform: x
            .interpolate({
              range: [0, 0.8, 1],
              output: [2, 2, 0],
            })
            .interpolate((x) => `translateY(${x}rem`),
        }}
      >
        <Suspense fallback={<Skeleton />}>
          <Content show={open}>
            {children}
            <CloseButton onClick={back}>Go back</CloseButton>
          </Content>
        </Suspense>
      </Fader>
    </ProjectPageWrapper>
  )
}

export default ProjectPage
